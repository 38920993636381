<template>
  <label
    class="checkbox-container block relative cursor-pointer select-none text-small-regular h-full"
  >
    <div class="relative pl-32">
      <WysiwygWrapper
        v-if="text"
        :style-config="{
          'wysiwyg wysiwyg--sm-text inline-block': true,
        }"
        :html="text"
      />
      <input
        class="absolute opacity-0 cursor-pointer h-0 w-0"
        type="checkbox"
        :checked="checked"
        :value="value"
        :name="name"
        @change="onCheck"
      >
      <span
        class="checkmark absolute left-0 bg-transparent rounded border border-grey400 animate-all"
      />
    </div>
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  methods: {
    onCheck(e: any) {
      if (this.value > 0) {
        this.$emit('onCheck', this.value);
      } else {
        this.$emit('onCheck', e.target.checked);
      }
    },
  },
});
</script>

<style>
.checkmark {
  height: 20px;
  width: 20px;
}

/* 
.checkbox-container:hover input ~ .checkmark {
  @apply bg-grey200;
}
*/

.checkbox-container:hover input ~ .checkmark {
  @apply border-black;
}

.checkbox-container input:checked ~ .checkmark {
  @apply border-none bg-success;
}

.checkmark::after {
  content: '';
  @apply opacity-0 absolute;
}

.checkbox-container input:checked ~ .checkmark::after {
  @apply opacity-100;
}

.checkbox-container .checkmark::after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
